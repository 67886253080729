@import '../../styles/variables.scss';

.navbar {
  height: $navbar-height;
  background-color: #24292e;
  letter-spacing: 0.5px;
}

.navLink {
  text-decoration: none;
}
