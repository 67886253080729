.reconcile-ip-modal {
  width: 70vw;
  max-width: 60rem;
  height: 80vh;
  padding: 1rem;
  background-color: white;
  position: relative;

  .close-reconcile-modal-button {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.1rem;
    margin: 0.5rem;

    &:hover {
      cursor: pointer;
    }
  }

  .reconcile-modal-header {
    height: 15%;
    width: 100%;

    h3 {
      margin: 0;
      height: 40%;
    }
    p {
      margin: 0;
    }
  }

  .reconcile-modal-body {
    height: 85%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .reconcile-instruction-container {
      width: 100%;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;

      &:nth-child(-n + 2) {
        height: 15%;
      }
      &:nth-child(3) {
        height: 70%;
        justify-content: flex-start;
      }

      .reconcile-instruction-header {
        width: 100%;
        height: 15%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: bold;

        .number-circle {
          height: 1.5rem;
          min-height: 1.5rem;
          width: 1.5rem;
          min-width: 1.5rem;
          margin-right: 0.75rem;
          border-radius: 50%;
          border: 2px solid black;
          color: black;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          font-size: 1rem;
        }

        p {
          margin: 0;
        }
      }

      .reconcile-instruction-body {
        width: 100%;
        height: 85%;
        padding: 0 3rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .ip-report-csv {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: space-between;

          label {
            margin: 0 3rem 0 0;
          }
        }

        .reconcile-results-container {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          overflow: hidden;

          .reconcile-results {
            width: 100%;
            height: 85%;
            border: 1px solid lightgray;
            overflow: hidden;

            .reconcile-results-header,
            .reconcile-results-body {
              width: 100%;

              p,
              div {
                margin: 0;
              }
            }

            .reconcile-results-header {
              height: 10%;
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              align-items: center;
              border-bottom: 1px solid lightgray;

              p {
                flex: 1;
              }
            }

            .reconcile-results-body {
              height: 90%;
              overflow-x: hidden;
              overflow-y: auto;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;

              .no-discrepancies-container {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                i {
                  font-size: 2rem;
                  color: green;
                  margin-bottom: 1rem;
                }
              }

              .discrepant-device {
                width: 100%;
                margin: 0;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                border-bottom: 1px solid rgb(219, 219, 219);

                &:hover {
                  background-color: #657da1;
                  color: white;
                }

                p {
                  flex: 1;
                }
              }
            }
          }

          .export-results-button-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
          }
        }
      }
    }
  }
}
