.importCSV-modal {
  height: 500px;
  width: 750px;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  position: relative;
}

.csv-upload-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0 0.5rem 0;
  border-bottom: 1px solid lightgray;
  width: 60%;

  label {
    width: 10rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
  }

  input {
    width: 13rem;
  }
}

.upload-status {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .upload-status-header {
    height: 15%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    .csv-close-button {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0.25rem;
    }
  }

  h4 {
    margin-bottom: 1.5rem;
  }

  .upload-status-category-container {
    height: 85%;
    width: 100%;
    overflow: auto;
  }
}

.results-container {
  width: 100%;
  height: 80%;
  overflow: auto;
}

.device-upload-status-row {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgray;
}

.upload-ip-header {
  width: 80%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0;
    text-decoration: underline;
  }
}

.import-typeahead-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 60%;

  p {
    margin: 0;
  }
}

.bulk-import-box {
  width: 80%;
  height: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
