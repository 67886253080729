@import '../../styles/variables.scss';

.container {
  width: 600px;
  height: 600px;
  box-shadow: 0 0 10px 1px #b0b0b0;
  padding: 2rem;
  position: relative;
}

.detailsBox {
  height: 400px;
  width: 400px;
  margin: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  div {
    width: 20rem;
    margin: 0.5rem;
    text-align: left;
  }

  input {
    margin-left: -1rem;
  }
}

.passwordBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.changePasswordButton {
  color: teal;
  font-size: 0.85rem;
  margin-left: 1rem;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.backButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5rem 1rem 0.5rem 1rem;
  color: $accent-orange;
  font-size: 0.85rem;

  &:hover {
    cursor: pointer;
    color: #865a07;
  }
}

.password-match {
  outline: 1px solid green;
  color: green;
}
