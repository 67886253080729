@import '../../styles/variables.scss';

.reports-page {
  height: calc(100vh - #{$navbar-height});
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;

  h3 {
    margin: 1rem;
  }

  .reports-page-left-menu {
    width: 10rem;
    height: 100%;
    padding: 0.5rem 0.75rem;
    background-color: $dk-gray;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    strong {
      font-size: 1.1rem;
    }

    .report-selection-container {
      width: 100%;
      height: auto;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      p {
        margin: 0;
      }

      .report-category-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .report-category-title {
          margin-top: 0.5rem;
        }

        .report-title {
          margin-left: 1rem;
          font-size: 0.9rem;
          &:hover {
            color: $accent-orange;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .selected-report {
          color: $accent-orange;
          text-decoration: underline;
          cursor: default;
          &:hover {
            cursor: default;
          }
        }
      }
    }
  }

  .report-container {
    height: 100%;
    width: calc(100% - 10rem);

    rect {
      word-wrap: break-word;
    }

    .report-header {
      width: 100%;
      height: 3rem;
      padding: 1rem 0.5rem;
      background-color: $med-gray;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .report-header-left-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        p {
          color: white;
          margin: 0 0.5rem;
        }

        .report-title {
          font-size: 1.25rem;
        }

        .timeframe-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          p {
            color: rgba(248, 153, 11, 0.65);
            &:hover {
              color: $accent-orange;
              cursor: pointer;
              text-decoration: underline;
            }
          }

          .selected-timeframe {
            color: $accent-orange;
            text-decoration: underline;
            &:hover {
              cursor: default;
            }
          }
        }
      }

      .report-header-right-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        select {
          margin-right: 0.5rem;
        }
      }
    }

    .report-content {
      width: 100%;
      height: calc(100% - 3rem);
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      .bar-loader-container {
        width: 100%;
        position: absolute;
        top: 0;
      }

      .report-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .chart-header {
          height: 10%;
          width: 100%;
        }

        .chart-container {
          height: 90%;
          width: 100%;
        }
      }
    }
  }
}
