@import './variables.scss';

.modalWrapper {
  height: 100%;
  width: 100%;
  background-color: #0c0b0bb7;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.editButton {
  font-size: 0.8rem;
  color: $accent-orange;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}

.hoverCursor:hover {
  cursor: pointer;
}

.modalAddRemoveItem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  p:nth-child(1) {
    margin-right: 1rem;
  }

  p:nth-child(2) {
    font-size: 0.75rem;
    color: salmon;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.progressContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    color: $accent-blue;
  }
}
