@import './styles/variables.scss';

* {
  box-sizing: border-box;
  margin: 0;
}

.App {
  height: 100vh;
  width: 100vw;
  text-align: center;
  overflow: hidden;
}

.auth-modal {
  height: 400px;
  width: 300px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;

  h3 {
    margin-bottom: 1rem;
  }

  input {
    margin: 0.5rem;
    outline: none;
    border-radius: 4px;
    &:focus {
      transition: 0.15s ease-in-out;
      box-shadow: 0px 0px 5px 1px #66afe9;
      -webkit-box-shadow: 0px 0px 5px 1px #66afe9;
      -moz-box-shadow: 0px 0px 5px 1px #66afe9;
      border-color: none;
    }
  }

  button {
    margin: 0.5rem;
  }
}
