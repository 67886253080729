@import '../../../styles/variables.scss';

.emailPasswordContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .changePasswordButton {
    position: absolute;
    bottom: 0;
  }
}

.rolesContainer {
  max-height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.roleList {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.changePasswordModal {
  height: 400px;
  width: 400px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;

  .changePasswordBox {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    input {
      margin-bottom: 1rem;
    }
  }

  .changePasswordModalButtons {
    width: 50%;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    p {
      margin: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
