.selectedServiceDomain {
  background-color: rgba(224, 224, 224, 0.432);
  font-weight: 500;
}

.deleteServiceDomainContainer {
  position: absolute;
  left: 1rem;
  bottom: 0.5rem;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  i {
    color: red;
  }
}
