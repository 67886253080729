.generalMessageModal {
  width: 400px;
  height: 300px;
  background-color: white;
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
}

.errorList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
