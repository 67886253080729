.exportDevicesModal {
  width: 250px;
  height: 150px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;

  i {
    position: absolute;
    top: 0;
    right: 0;
		margin: 0.5rem;
		&:hover {
			cursor: pointer;
		}
  }
}
