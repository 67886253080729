@import '../../styles/variables.scss';

.headerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.headerServiceNum {
  font-size: 1.5rem;
  margin: 0.5rem 0;
  text-align: left;
}

.backButton {
  color: $accent-orange;
  font-size: 0.85rem;
  margin: 0;

  &:hover {
    cursor: pointer;
    color: #865a07;
  }
}

.panelContainer {
  width: 100%;
  height: 100%;
  padding: 0 1rem 2rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.panel {
  height: 100%;
  margin: 0 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid gray;
  overflow: hidden;
}

.panelHeader {
  font-size: 1.25rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }
}

.authAttemptsHeaderRow,
.sessionHeaderRow {
  width: 100%;
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  background-color: $dk-gray;
  color: white;
  padding: 0 0.5rem;

  span {
    margin: 0;
    font-weight: 500;
    word-break: break-word;
  }
}

.authAttemptsContainer,
.sessionsContainer {
  width: 100%;
  height: calc(100% - 7rem);
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.authAttemptsRow,
.sessionRow {
  width: 100%;
  height: auto;
  min-height: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  border-bottom: 1px solid lightgray;
  padding: 0 0.5rem;
  overflow: hidden;

  span {
    width: 100%;
    padding: 0 0.25rem;
    height: auto;
  }
}

.authAttemptedUsername,
.authDeviceUsername,
.authAttemptedPassword,
.authDevicePassword,
.authAttemptDate,
.authAttemptStatus,
.authAttemptedUsername,
.authDeviceUsername {
  width: 100%;
  word-wrap: break-word;
}

.authAttemptStatus {
  max-width: 5rem;
}

.authAttemptDate {
  word-wrap: break-word;
}

.sessionStart,
.sessionDuration,
.sessionTransferredData,
.sessionTermination {
  width: 100%;
  word-wrap: break-word;
}

.sessionTransferredData {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  span,
  i {
    margin: 0 0.25rem;
  }

  i {
    font-size: 0.9rem;
    color: white;
  }
}
