@import '../../../styles/variables.scss';

a {
  text-decoration: none;
  color: black;
  &:hover {
    color: white;
  }
}

i {
  text-decoration: none;
  color: black;
}

input {
  max-width: 100%;
}

.row {
  height: auto;
  min-height: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  word-break: break-all;
  border-bottom: 1px solid lightgray;
  transition: 0.15s;
  font-size: 0.9rem;

  &:hover {
    transition: 0.15s;
    background-color: #657da1;
    color: white;
  }

  p {
    margin: 0;
  }

  input,
  select {
    width: 90%;
  }
}

.editRow {
  min-height: 4rem;
  background-color: #657da1;
  color: white;
}

.configFileButtonContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  align-items: center;

  i {
    margin: 0 0.5rem;
    &:hover {
      cursor: pointer;
      color: $dk-gray;
    }
  }
}

.typeaheadContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  div > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
