@import '../../styles/variables.scss';

.container {
  height: 80vh;
  width: 75vw;
  max-width: 1400px;
  margin-top: 1rem;
}

.servicePanel {
  height: 80vh;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px 1px rgba(176, 176, 176, 1);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem;
}

.servicePanelColumnContainer {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 1rem;

  h5 {
    margin: 0 1rem 1rem 1rem;
  }
  span {
    height: 80%;
    border-right: 1px solid lightgray;
  }
}

.servicePanelListContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 47.5%;
}

.servicePanelList {
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: auto;
  padding: 1rem;
  margin: 1rem;
}

.serviceItem {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0.25rem;

  p {
    margin-bottom: 0;
    text-align: left;
  }
}

.selectServiceText {
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    text-decoration: underline;
  }
}

.editServiceButton {
  margin-left: 2rem;
  font-size: 0.8rem;
  color: $accent-orange;
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
}

.editServiceButtonUnselected {
  display: none;
}

// Add & Edit Modal
.editAddServiceModal {
  height: 25rem;
  width: 25rem;
  padding: 1rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  word-wrap: break-word;
}

.serviceModalInputs {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 1rem;
  }
}

.serviceModalButtons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  p {
    margin: 1rem;

    &:hover {
      transform: scale(1.05);
      cursor: pointer;
    }
  }

  p:nth-child(2) {
    &:hover {
      color: forestgreen;
    }
  }

  p:nth-child(1) {
    &:hover {
      color: $accent-orange;
    }
  }
}
