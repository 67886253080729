@import '../../../styles/variables.scss';

.editCustomerRow {
  div:nth-child(2) > div > div > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    input.rbt-input-main.form-control.rbt-input {
      width: 80%;
    }
  }
}
