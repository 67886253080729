@import '../../styles/variables.scss';

.pageSelectionContainer {
  height: 2rem;
  width: 100%;
  padding: 0 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  i,
  span {
    margin: 0 0.25rem;
  }

  i {
    &:hover {
      color: $accent-blue;
    }
  }
}

.tableContainer {
  height: calc(100% - 2rem);
  width: 100%;
  padding: 0 2rem 2rem 2rem;
}

.table {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  box-shadow: 0 0 10px 1px rgba(176, 176, 176, 1);
  background-color: white;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: #535b61;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
  }
}

.header {
  height: 4rem;
  width: 141rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.columnTitleRow {
  background-color: $dk-gray;
  color: white;
  border: none;
  font-weight: bold;
  position: relative;
}

.filterRow {
  border-bottom: 1px solid gray;
  background-color: $med-gray;
  border: none;
  font-weight: bold;

  .clearFiltersButton {
    color: white;
    margin: 0;
    font-size: 0.8rem;

    &:hover {
      color: lightgray;
    }
  }
}

.columnTitleRow,
.filterRow {
  height: auto;
  min-height: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;

  div,
  p {
    margin: 0;
  }

  input,
  select {
    width: 90%;
  }
}

.body {
  height: calc(100% - 4rem);
  width: 141rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-y: overlay;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: #535b61;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
  }
}

.reconcileDevicesButton {
  margin-right: 0.5rem;

  &:hover {
    color: $accent-blue;
  }
}

.exportDevicesButton {
  &:hover {
    color: $accent-blue;
  }
}

.exportButtonLoaderContainer {
  margin-right: 1rem;
}
