@import '../../styles/variables.scss';

.tableContainer {
  height: 100%;
  width: 100%;
  max-width: 1200px;
  padding: 0 2rem 2rem 2rem;

  .users-table {
    max-height: 50rem;
  }
}
