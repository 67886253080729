@import '../styles/variables.scss';

//Right Add / Edit Slider Menu
.rightMenu {
  height: calc(100vh - #{$navbar-height});
  width: 300px;
  background-color: rgba(36, 41, 46, 0.95);
  position: absolute;
  top: 0;
  right: -300px;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  z-index: 3;

  .rightMenuTypeahead {
    width: 12rem;
  }

  .addMenuInputs {
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 3rem;
      width: 12rem;
      margin-top: 0.5rem;

      .requiredStar {
        color: white;
        position: absolute;
        right: -1rem;
        top: 1rem;
        margin: 0;
      }
    }

    input {
      width: 12rem;
      height: 2rem;
      border-radius: 5px;
      margin: 0.5rem 0;
      border: none;
      outline: none;
      border: 3px solid transparent;

      &:focus {
        border-color: $accent-blue;
      }
    }
  }

  button {
    margin: 1rem;
  }
}

.slideRightMenu {
  right: 0;
}
