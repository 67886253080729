@import '../../../styles/variables.scss';

.selectedService {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(224, 224, 224, 0.432);
  font-weight: 500;
}

.selectServiceText {
  color: $accent-blue;

  &:hover {
    text-decoration: none;
    cursor: default;
    transform: none;
  }
}
