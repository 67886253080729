.editAssignedModal {
  width: 800px;
  height: 600px;
  background-color: white;
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.assignedUnassignedList {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: flex-start;

  h3 {
    margin: 1rem;
    text-decoration: underline;
  }
}

.editModalDescriptions {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  font-size: 0.85rem;

  p {
    margin: 0 0 1rem 1.5rem;
  }
}

.modalCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  height: 3rem;
  width: 3rem;
  font-size: 2rem;

  &:hover {
    cursor: pointer;
    color: darkslategrey;
  }
}
