* {
  font-family: 'Roboto', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
  margin: 0; }

.App {
  height: 100vh;
  width: 100vw;
  text-align: center;
  overflow: hidden; }

.auth-modal {
  height: 400px;
  width: 300px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem; }
  .auth-modal h3 {
    margin-bottom: 1rem; }
  .auth-modal input {
    margin: 0.5rem;
    outline: none;
    border-radius: 4px; }
    .auth-modal input:focus {
      transition: 0.15s ease-in-out;
      box-shadow: 0px 0px 5px 1px #66afe9;
      -webkit-box-shadow: 0px 0px 5px 1px #66afe9;
      -moz-box-shadow: 0px 0px 5px 1px #66afe9;
      border-color: none; }
  .auth-modal button {
    margin: 0.5rem; }

.Header_navbar__3ycxV {
  height: 3em;
  background-color: #24292e;
  letter-spacing: 0.5px; }

.Header_navLink__3eUOU {
  text-decoration: none; }

.page_page__3XE_0 {
  height: calc(100vh - 3em);
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative; }

.page_header__26hlh {
  height: 10%;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.page_title__S9o2s {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0; }

.page_buttonInputContainer__TBTDa {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem; }
  .page_buttonInputContainer__TBTDa a {
    text-decoration: none;
    color: inherit; }
  .page_buttonInputContainer__TBTDa i {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .page_buttonInputContainer__TBTDa i:hover {
      cursor: pointer; }

.page_body__3Q77K {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }

.page_searchBox__1m4LU {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative; }
  .page_searchBox__1m4LU input {
    outline: none;
    border: 2px solid #6c757d; }
    .page_searchBox__1m4LU input:focus {
      border-color: #16a2b8; }
  .page_searchBox__1m4LU p {
    display: none; }
  .page_searchBox__1m4LU:hover p {
    display: block;
    margin: 0;
    position: absolute;
    right: 0.5rem;
    z-index: 2;
    color: gray;
    transition: 0.1s; }
    .page_searchBox__1m4LU:hover p:hover {
      cursor: pointer;
      color: black;
      transition: 0.1s; }

.table_table__1xaEC {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 0 10px 1px #b0b0b0; }

.table_header__2OXlb {
  height: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }

.table_columnTitleRow__2b2oO {
  background-color: #545b61;
  color: white;
  border: none;
  font-weight: bold; }

.table_filterRow__7jfEV {
  border-bottom: 1px solid gray;
  background-color: #6c757d;
  border: none;
  font-weight: bold; }
  .table_filterRow__7jfEV .table_clearFiltersButton__j9zaz {
    color: white;
    margin: 0;
    font-size: 0.8rem; }
    .table_filterRow__7jfEV .table_clearFiltersButton__j9zaz:hover {
      color: lightgray; }

.table_sortArrow__3yizN {
  position: absolute;
  margin-left: 2px; }

.table_columnTitleRow__2b2oO,
.table_filterRow__7jfEV,
.table_row__2FLK0 {
  height: auto;
  min-height: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow: hidden; }
  .table_columnTitleRow__2b2oO div,
  .table_columnTitleRow__2b2oO p,
  .table_filterRow__7jfEV div,
  .table_filterRow__7jfEV p,
  .table_row__2FLK0 div,
  .table_row__2FLK0 p {
    margin: 0; }
  .table_columnTitleRow__2b2oO input,
  .table_columnTitleRow__2b2oO select,
  .table_filterRow__7jfEV input,
  .table_filterRow__7jfEV select,
  .table_row__2FLK0 input,
  .table_row__2FLK0 select {
    width: 90%; }

.table_columnTitleRow__2b2oO > div,
.table_filterRow__7jfEV > div,
.table_row__2FLK0 > div {
  flex: 1 1; }

.table_body__1CysV {
  height: calc(100% - 4rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-y: overlay; }

.table_row__2FLK0 {
  height: auto;
  min-height: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  word-break: break-all;
  border-bottom: 1px solid lightgray;
  transition: 0.15s;
  font-size: 0.9rem; }
  .table_row__2FLK0:hover {
    transition: 0.15s;
    background-color: #657da1;
    color: white; }

.table_editRow__1XsY7 {
  height: 4rem;
  min-height: 4rem;
  background-color: #657da1;
  color: white; }

.table_rowButtonContainer__WaZbp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem; }
  .table_rowButtonContainer__WaZbp p:hover {
    cursor: pointer; }

.table_saveButton__3m6zc {
  color: #45d200; }

.table_cancelEditButton__2ko8R {
  color: white; }

.rightMenu_rightMenu__Rx4Ya {
  height: calc(100vh - 3em);
  width: 300px;
  background-color: rgba(36, 41, 46, 0.95);
  position: absolute;
  top: 0;
  right: -300px;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  z-index: 3; }
  .rightMenu_rightMenu__Rx4Ya .rightMenu_rightMenuTypeahead__6BQYJ {
    width: 12rem; }
  .rightMenu_rightMenu__Rx4Ya .rightMenu_addMenuInputs__3tMiL {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .rightMenu_rightMenu__Rx4Ya .rightMenu_addMenuInputs__3tMiL div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 3rem;
      width: 12rem;
      margin-top: 0.5rem; }
      .rightMenu_rightMenu__Rx4Ya .rightMenu_addMenuInputs__3tMiL div .rightMenu_requiredStar__1qIwz {
        color: white;
        position: absolute;
        right: -1rem;
        top: 1rem;
        margin: 0; }
    .rightMenu_rightMenu__Rx4Ya .rightMenu_addMenuInputs__3tMiL input {
      width: 12rem;
      height: 2rem;
      border-radius: 5px;
      margin: 0.5rem 0;
      border: none;
      outline: none;
      border: 3px solid transparent; }
      .rightMenu_rightMenu__Rx4Ya .rightMenu_addMenuInputs__3tMiL input:focus {
        border-color: #16a2b8; }
  .rightMenu_rightMenu__Rx4Ya button {
    margin: 1rem; }

.rightMenu_slideRightMenu__1Ycnu {
  right: 0; }

.misc_modalWrapper__39nrw {
  height: 100%;
  width: 100%;
  background-color: #0c0b0bb7;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }

.misc_editButton__9-NcN {
  font-size: 0.8rem;
  color: #f8990b;
  font-weight: bold; }
  .misc_editButton__9-NcN:hover {
    cursor: pointer; }

.misc_hoverCursor__2HiCG:hover {
  cursor: pointer; }

.misc_modalAddRemoveItem__1OJHD {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center; }
  .misc_modalAddRemoveItem__1OJHD p:nth-child(1) {
    margin-right: 1rem; }
  .misc_modalAddRemoveItem__1OJHD p:nth-child(2) {
    font-size: 0.75rem;
    color: salmon; }
    .misc_modalAddRemoveItem__1OJHD p:nth-child(2):hover {
      cursor: pointer;
      text-decoration: underline; }

.misc_progressContainer__1zPS7 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .misc_progressContainer__1zPS7 div {
    color: #16a2b8; }

.UsersPage_tableContainer__koFNJ {
  height: 100%;
  width: 100%;
  max-width: 1200px;
  padding: 0 2rem 2rem 2rem; }
  .UsersPage_tableContainer__koFNJ .UsersPage_users-table__KZv58 {
    max-height: 50rem; }

.User_emailPasswordContainer__3ZFpR {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative; }
  .User_emailPasswordContainer__3ZFpR .User_changePasswordButton__4OHcX {
    position: absolute;
    bottom: 0; }

.User_rolesContainer__3VKLj {
  max-height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.User_roleList__2HNe8 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; }

.User_changePasswordModal__3S5Xn {
  height: 400px;
  width: 400px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem; }
  .User_changePasswordModal__3S5Xn .User_changePasswordBox__4lWoA {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }
    .User_changePasswordModal__3S5Xn .User_changePasswordBox__4lWoA input {
      margin-bottom: 1rem; }
  .User_changePasswordModal__3S5Xn .User_changePasswordModalButtons__1AMrM {
    width: 50%;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center; }
    .User_changePasswordModal__3S5Xn .User_changePasswordModalButtons__1AMrM p {
      margin: 0; }
      .User_changePasswordModal__3S5Xn .User_changePasswordModalButtons__1AMrM p:hover {
        cursor: pointer; }

.EditAssignedModal_editAssignedModal__3LSdX {
  width: 800px;
  height: 600px;
  background-color: white;
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.EditAssignedModal_assignedUnassignedList__2Bda0 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: flex-start; }
  .EditAssignedModal_assignedUnassignedList__2Bda0 h3 {
    margin: 1rem;
    text-decoration: underline; }

.EditAssignedModal_editModalDescriptions__18W9h {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  font-size: 0.85rem; }
  .EditAssignedModal_editModalDescriptions__18W9h p {
    margin: 0 0 1rem 1.5rem; }

.EditAssignedModal_modalCloseButton__16rz0 {
  position: absolute;
  top: 0;
  right: 0;
  height: 3rem;
  width: 3rem;
  font-size: 2rem; }
  .EditAssignedModal_modalCloseButton__16rz0:hover {
    cursor: pointer;
    color: darkslategrey; }

h3.popover-header {
  background-color: #24292e;
  color: white; }

.CustomersPage_tableContainer__F_DIE {
  height: 100%;
  width: 100%;
  max-width: 1600px;
  padding: 0 2rem 2rem 2rem; }

.Customer_editCustomerRow__2iOFx div:nth-child(2) > div > div > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .Customer_editCustomerRow__2iOFx div:nth-child(2) > div > div > div input.Customer_rbt-input-main__1uWmw.Customer_form-control__1BIZe.Customer_rbt-input__3dRQR {
    width: 80%; }

.DevicesPage_pageSelectionContainer__3N2pL {
  height: 2rem;
  width: 100%;
  padding: 0 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }
  .DevicesPage_pageSelectionContainer__3N2pL i,
  .DevicesPage_pageSelectionContainer__3N2pL span {
    margin: 0 0.25rem; }
  .DevicesPage_pageSelectionContainer__3N2pL i:hover {
    color: #16a2b8; }

.DevicesPage_tableContainer__3ucSH {
  height: calc(100% - 2rem);
  width: 100%;
  padding: 0 2rem 2rem 2rem; }

.DevicesPage_table__5tgrc {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  box-shadow: 0 0 10px 1px #b0b0b0;
  background-color: white; }
  .DevicesPage_table__5tgrc::-webkit-scrollbar {
    -webkit-appearance: none; }
  .DevicesPage_table__5tgrc::-webkit-scrollbar:horizontal {
    height: 10px; }
  .DevicesPage_table__5tgrc::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    /* should match background, can't be transparent */
    background-color: #535b61; }
  .DevicesPage_table__5tgrc::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px; }

.DevicesPage_header__33uzo {
  height: 4rem;
  width: 141rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }

.DevicesPage_columnTitleRow__3faKF {
  background-color: #545b61;
  color: white;
  border: none;
  font-weight: bold;
  position: relative; }

.DevicesPage_filterRow__DCd20 {
  border-bottom: 1px solid gray;
  background-color: #6c757d;
  border: none;
  font-weight: bold; }
  .DevicesPage_filterRow__DCd20 .DevicesPage_clearFiltersButton__WrU3M {
    color: white;
    margin: 0;
    font-size: 0.8rem; }
    .DevicesPage_filterRow__DCd20 .DevicesPage_clearFiltersButton__WrU3M:hover {
      color: lightgray; }

.DevicesPage_columnTitleRow__3faKF,
.DevicesPage_filterRow__DCd20 {
  height: auto;
  min-height: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow: hidden; }
  .DevicesPage_columnTitleRow__3faKF div,
  .DevicesPage_columnTitleRow__3faKF p,
  .DevicesPage_filterRow__DCd20 div,
  .DevicesPage_filterRow__DCd20 p {
    margin: 0; }
  .DevicesPage_columnTitleRow__3faKF input,
  .DevicesPage_columnTitleRow__3faKF select,
  .DevicesPage_filterRow__DCd20 input,
  .DevicesPage_filterRow__DCd20 select {
    width: 90%; }

.DevicesPage_body__1CAiP {
  height: calc(100% - 4rem);
  width: 141rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-y: overlay; }
  .DevicesPage_body__1CAiP::-webkit-scrollbar {
    -webkit-appearance: none; }
  .DevicesPage_body__1CAiP::-webkit-scrollbar:vertical {
    width: 10px; }
  .DevicesPage_body__1CAiP::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    /* should match background, can't be transparent */
    background-color: #535b61; }
  .DevicesPage_body__1CAiP::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px; }

.DevicesPage_reconcileDevicesButton__-riLN {
  margin-right: 0.5rem; }
  .DevicesPage_reconcileDevicesButton__-riLN:hover {
    color: #16a2b8; }

.DevicesPage_exportDevicesButton__4FSKG:hover {
  color: #16a2b8; }

.DevicesPage_exportButtonLoaderContainer__3UP86 {
  margin-right: 1rem; }

a {
  text-decoration: none;
  color: black; }
  a:hover {
    color: white; }

i {
  text-decoration: none;
  color: black; }

input {
  max-width: 100%; }

.Device_row__Poje8 {
  height: auto;
  min-height: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  word-break: break-all;
  border-bottom: 1px solid lightgray;
  transition: 0.15s;
  font-size: 0.9rem; }
  .Device_row__Poje8:hover {
    transition: 0.15s;
    background-color: #657da1;
    color: white; }
  .Device_row__Poje8 p {
    margin: 0; }
  .Device_row__Poje8 input,
  .Device_row__Poje8 select {
    width: 90%; }

.Device_editRow__2qsJP {
  min-height: 4rem;
  background-color: #657da1;
  color: white; }

.Device_configFileButtonContainer__1xr3Z {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  align-items: center; }
  .Device_configFileButtonContainer__1xr3Z i {
    margin: 0 0.5rem; }
    .Device_configFileButtonContainer__1xr3Z i:hover {
      cursor: pointer;
      color: #545b61; }

.Device_typeaheadContainer__21UOT {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .Device_typeaheadContainer__21UOT div > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }

.importCSV-modal {
  height: 500px;
  width: 750px;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  position: relative; }

.csv-upload-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0 0.5rem 0;
  border-bottom: 1px solid lightgray;
  width: 60%; }
  .csv-upload-button label {
    width: 10rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0; }
  .csv-upload-button input {
    width: 13rem; }

.upload-status {
  height: 100%;
  width: 100%;
  overflow: hidden; }
  .upload-status .upload-status-header {
    height: 15%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative; }
    .upload-status .upload-status-header .csv-close-button {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0.25rem; }
  .upload-status h4 {
    margin-bottom: 1.5rem; }
  .upload-status .upload-status-category-container {
    height: 85%;
    width: 100%;
    overflow: auto; }

.results-container {
  width: 100%;
  height: 80%;
  overflow: auto; }

.device-upload-status-row {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgray; }

.upload-ip-header {
  width: 80%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  .upload-ip-header p {
    margin: 0;
    text-decoration: underline; }

.import-typeahead-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 60%; }
  .import-typeahead-title p {
    margin: 0; }

.bulk-import-box {
  width: 80%;
  height: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center; }

.reconcile-ip-modal {
  width: 70vw;
  max-width: 60rem;
  height: 80vh;
  padding: 1rem;
  background-color: white;
  position: relative; }
  .reconcile-ip-modal .close-reconcile-modal-button {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.1rem;
    margin: 0.5rem; }
    .reconcile-ip-modal .close-reconcile-modal-button:hover {
      cursor: pointer; }
  .reconcile-ip-modal .reconcile-modal-header {
    height: 15%;
    width: 100%; }
    .reconcile-ip-modal .reconcile-modal-header h3 {
      margin: 0;
      height: 40%; }
    .reconcile-ip-modal .reconcile-modal-header p {
      margin: 0; }
  .reconcile-ip-modal .reconcile-modal-body {
    height: 85%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container {
      width: 100%;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start; }
      .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container:nth-child(-n + 2) {
        height: 15%; }
      .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container:nth-child(3) {
        height: 70%;
        justify-content: flex-start; }
      .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-header {
        width: 100%;
        height: 15%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: bold; }
        .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-header .number-circle {
          height: 1.5rem;
          min-height: 1.5rem;
          width: 1.5rem;
          min-width: 1.5rem;
          margin-right: 0.75rem;
          border-radius: 50%;
          border: 2px solid black;
          color: black;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          font-size: 1rem; }
        .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-header p {
          margin: 0; }
      .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body {
        width: 100%;
        height: 85%;
        padding: 0 3rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center; }
        .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .ip-report-csv {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: space-between; }
          .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .ip-report-csv label {
            margin: 0 3rem 0 0; }
        .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .reconcile-results-container {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          overflow: hidden; }
          .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .reconcile-results-container .reconcile-results {
            width: 100%;
            height: 85%;
            border: 1px solid lightgray;
            overflow: hidden; }
            .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .reconcile-results-container .reconcile-results .reconcile-results-header,
            .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .reconcile-results-container .reconcile-results .reconcile-results-body {
              width: 100%; }
              .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .reconcile-results-container .reconcile-results .reconcile-results-header p,
              .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .reconcile-results-container .reconcile-results .reconcile-results-header div,
              .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .reconcile-results-container .reconcile-results .reconcile-results-body p,
              .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .reconcile-results-container .reconcile-results .reconcile-results-body div {
                margin: 0; }
            .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .reconcile-results-container .reconcile-results .reconcile-results-header {
              height: 10%;
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              align-items: center;
              border-bottom: 1px solid lightgray; }
              .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .reconcile-results-container .reconcile-results .reconcile-results-header p {
                flex: 1 1; }
            .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .reconcile-results-container .reconcile-results .reconcile-results-body {
              height: 90%;
              overflow-x: hidden;
              overflow-y: auto;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center; }
              .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .reconcile-results-container .reconcile-results .reconcile-results-body .no-discrepancies-container {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center; }
                .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .reconcile-results-container .reconcile-results .reconcile-results-body .no-discrepancies-container i {
                  font-size: 2rem;
                  color: green;
                  margin-bottom: 1rem; }
              .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .reconcile-results-container .reconcile-results .reconcile-results-body .discrepant-device {
                width: 100%;
                margin: 0;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                border-bottom: 1px solid #dbdbdb; }
                .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .reconcile-results-container .reconcile-results .reconcile-results-body .discrepant-device:hover {
                  background-color: #657da1;
                  color: white; }
                .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .reconcile-results-container .reconcile-results .reconcile-results-body .discrepant-device p {
                  flex: 1 1; }
          .reconcile-ip-modal .reconcile-modal-body .reconcile-instruction-container .reconcile-instruction-body .reconcile-results-container .export-results-button-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end; }

.ExportDevicesModal_exportDevicesModal__4mUVP {
  width: 250px;
  height: 150px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative; }
  .ExportDevicesModal_exportDevicesModal__4mUVP i {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem; }
    .ExportDevicesModal_exportDevicesModal__4mUVP i:hover {
      cursor: pointer; }

.ServicesPage_container__2bBcH {
  height: 80vh;
  width: 75vw;
  max-width: 1400px;
  margin-top: 1rem; }

.ServicesPage_servicePanel__3MwXF {
  height: 80vh;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px 1px #b0b0b0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem; }

.ServicesPage_servicePanelColumnContainer__22GZ4 {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 1rem; }
  .ServicesPage_servicePanelColumnContainer__22GZ4 h5 {
    margin: 0 1rem 1rem 1rem; }
  .ServicesPage_servicePanelColumnContainer__22GZ4 span {
    height: 80%;
    border-right: 1px solid lightgray; }

.ServicesPage_servicePanelListContainer__2CpR_ {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 47.5%; }

.ServicesPage_servicePanelList__3lTpl {
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: auto;
  padding: 1rem;
  margin: 1rem; }

.ServicesPage_serviceItem__274zb {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0.25rem; }
  .ServicesPage_serviceItem__274zb p {
    margin-bottom: 0;
    text-align: left; }

.ServicesPage_selectServiceText__2GWT1:hover {
  cursor: pointer;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  text-decoration: underline; }

.ServicesPage_editServiceButton__3Xsnq {
  margin-left: 2rem;
  font-size: 0.8rem;
  color: #f8990b; }
  .ServicesPage_editServiceButton__3Xsnq:hover {
    cursor: pointer;
    font-weight: bold; }

.ServicesPage_editServiceButtonUnselected__2HaXL {
  display: none; }

.ServicesPage_editAddServiceModal__3vft3 {
  height: 25rem;
  width: 25rem;
  padding: 1rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  word-wrap: break-word; }

.ServicesPage_serviceModalInputs__3BN1p {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem; }
  .ServicesPage_serviceModalInputs__3BN1p div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 1rem; }

.ServicesPage_serviceModalButtons__26wOH {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .ServicesPage_serviceModalButtons__26wOH p {
    margin: 1rem; }
    .ServicesPage_serviceModalButtons__26wOH p:hover {
      -webkit-transform: scale(1.05);
              transform: scale(1.05);
      cursor: pointer; }
  .ServicesPage_serviceModalButtons__26wOH p:nth-child(2):hover {
    color: forestgreen; }
  .ServicesPage_serviceModalButtons__26wOH p:nth-child(1):hover {
    color: #f8990b; }

.Service_selectedService__8cHGR {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(224, 224, 224, 0.432);
  font-weight: 500; }

.Service_selectServiceText__2vtdZ {
  color: #16a2b8; }
  .Service_selectServiceText__2vtdZ:hover {
    text-decoration: none;
    cursor: default;
    -webkit-transform: none;
            transform: none; }

.ServiceDomain_selectedServiceDomain__3_uYg {
  background-color: rgba(224, 224, 224, 0.432);
  font-weight: 500; }

.ServiceDomain_deleteServiceDomainContainer__T1Gx2 {
  position: absolute;
  left: 1rem;
  bottom: 0.5rem; }
  .ServiceDomain_deleteServiceDomainContainer__T1Gx2:hover {
    cursor: pointer;
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  .ServiceDomain_deleteServiceDomainContainer__T1Gx2 i {
    color: red; }

.CategorizationPage_container__1sJR_ {
  height: 80vh;
  width: 90vw;
  max-width: 1800px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px 1px #b0b0b0;
  margin-top: 1rem;
  padding: 0.5rem; }
  .CategorizationPage_container__1sJR_ span {
    height: 80%;
    border-right: 1px solid lightgray; }

.CategorizationPage_categorizationPanel__veRRT {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem; }

.CategorizationPage_panelContentContainer__1_vKk {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  border: 1px solid lightgray; }
  .CategorizationPage_panelContentContainer__1_vKk p {
    margin: 0;
    text-align: left; }

.CategorizationPage_panelContentHeader__2ID4M {
  width: 100%;
  height: 1.5rem;
  background-color: #545b61;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem; }

.CategorizationPage_categorizationSearchBox__Q7_f0 {
  height: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative; }
  .CategorizationPage_categorizationSearchBox__Q7_f0 input {
    height: 1.5rem;
    width: 100%;
    margin: 0;
    padding-left: 0.5rem;
    outline: none;
    border: 2px solid #6c757d; }
    .CategorizationPage_categorizationSearchBox__Q7_f0 input:focus {
      border-color: #16a2b8; }
  .CategorizationPage_categorizationSearchBox__Q7_f0 p {
    display: none; }
  .CategorizationPage_categorizationSearchBox__Q7_f0:hover p {
    display: block;
    margin: 0;
    position: absolute;
    right: 0.5rem;
    z-index: 2;
    color: gray;
    transition: 0.1s; }
    .CategorizationPage_categorizationSearchBox__Q7_f0:hover p:hover {
      cursor: pointer;
      color: black;
      transition: 0.1s; }

.CategorizationPage_panelContentList__33loF {
  height: 100%;
  width: 100%;
  overflow: auto; }

.CategorizationPage_categorizationListItem__1Zhwz {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0.5rem;
  margin-bottom: 0.25rem; }
  .CategorizationPage_categorizationListItem__1Zhwz p:nth-child(1) {
    word-break: break-all; }
  .CategorizationPage_categorizationListItem__1Zhwz:hover {
    cursor: pointer; }
    .CategorizationPage_categorizationListItem__1Zhwz:hover p {
      -webkit-transform: scale(1.02);
              transform: scale(1.02);
      text-decoration: underline; }

.CategorizationPage_selectedCategorizationItem__39frc {
  background-color: rgba(224, 224, 224, 0.432);
  color: #16a2b8;
  font-weight: 500; }
  .CategorizationPage_selectedCategorizationItem__39frc:hover {
    cursor: default; }
    .CategorizationPage_selectedCategorizationItem__39frc:hover p {
      text-decoration: none;
      -webkit-transform: none;
              transform: none; }

.CategorizationPage_usageContainer__-Q_N9 {
  display: flex;
  flex-direction: row; }
  .CategorizationPage_usageContainer__-Q_N9 p {
    margin: 0 3px; }

.CategorizationPage_customerPanel__38u5J,
.CategorizationPage_devicePanel__2qXU3,
.CategorizationPage_uncategorizedDomainPanel__15LdD {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 3 1; }

.CategorizationPage_categorizeDomainModal__1fP4q {
  height: 600px;
  width: 600px;
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; }

.CategorizationPage_domainModalInputContainer__3Ll0P {
  height: auto;
  width: 100%; }
  .CategorizationPage_domainModalInputContainer__3Ll0P p {
    margin-bottom: 0.25rem; }
  .CategorizationPage_domainModalInputContainer__3Ll0P div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0.5rem; }

.CategorizationPage_createNewService__2ECSk {
  font-size: 0.8rem;
  color: #16a2b8; }
  .CategorizationPage_createNewService__2ECSk:hover {
    text-decoration: underline;
    cursor: pointer; }

.CategorizationPage_newServiceContainer__IiEdE {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }
  .CategorizationPage_newServiceContainer__IiEdE input {
    margin: 0.5rem 0; }
  .CategorizationPage_newServiceContainer__IiEdE p {
    font-size: 0.8rem;
    margin: 0 0.5rem; }
    .CategorizationPage_newServiceContainer__IiEdE p:hover {
      text-decoration: underline;
      cursor: pointer; }

.CategorizationPage_similarDomainsContainer__3t7e2 {
  height: 45%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; }
  .CategorizationPage_similarDomainsContainer__3t7e2 p {
    margin: 0; }

.CategorizationPage_selectAllDomains__125DB {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0.5rem; }

.CategorizationPage_similarDomainsList__rsAQo {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  border: 0.5px solid lightgray;
  padding: 0.5rem; }

.CategorizationPage_similarDomainListItem__rc5D2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }
  .CategorizationPage_similarDomainListItem__rc5D2 p {
    margin: 0; }

.CategorizationPage_categorizeDomainModalButtons__20MCL {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.SubnetsPage_tableContainer__1kOLc {
  height: 100%;
  width: 100%;
  padding: 0 2rem 2rem 2rem; }

.SubnetsPage_newSubnetText__3SxGc {
  color: white; }

.AccountPage_container__2tvIq {
  width: 600px;
  height: 600px;
  box-shadow: 0 0 10px 1px #b0b0b0;
  padding: 2rem;
  position: relative; }

.AccountPage_detailsBox__2rsrJ {
  height: 400px;
  width: 400px;
  margin: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; }
  .AccountPage_detailsBox__2rsrJ div {
    width: 20rem;
    margin: 0.5rem;
    text-align: left; }
  .AccountPage_detailsBox__2rsrJ input {
    margin-left: -1rem; }

.AccountPage_passwordBox__2NfN0 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; }

.AccountPage_changePasswordButton__kfus4 {
  color: teal;
  font-size: 0.85rem;
  margin-left: 1rem; }
  .AccountPage_changePasswordButton__kfus4:hover {
    cursor: pointer;
    text-decoration: underline; }

.AccountPage_backButton___xe4o {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5rem 1rem 0.5rem 1rem;
  color: #f8990b;
  font-size: 0.85rem; }
  .AccountPage_backButton___xe4o:hover {
    cursor: pointer;
    color: #865a07; }

.AccountPage_password-match__2naBX {
  outline: 1px solid green;
  color: green; }

.RadiusPage_headerContainer___wFDn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem; }
  .RadiusPage_headerContainer___wFDn a:hover {
    text-decoration: none; }

.RadiusPage_headerServiceNum__2Yi5a {
  font-size: 1.5rem;
  margin: 0.5rem 0;
  text-align: left; }

.RadiusPage_backButton__10440 {
  color: #f8990b;
  font-size: 0.85rem;
  margin: 0; }
  .RadiusPage_backButton__10440:hover {
    cursor: pointer;
    color: #865a07; }

.RadiusPage_panelContainer__1zNkc {
  width: 100%;
  height: 100%;
  padding: 0 1rem 2rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden; }

.RadiusPage_panel__3PqrQ {
  height: 100%;
  margin: 0 1rem;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid gray;
  overflow: hidden; }

.RadiusPage_panelHeader__3Cs1E {
  font-size: 1.25rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .RadiusPage_panelHeader__3Cs1E p {
    margin: 0; }

.RadiusPage_authAttemptsHeaderRow__VP8uB,
.RadiusPage_sessionHeaderRow__3pRy_ {
  width: 100%;
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  background-color: #545b61;
  color: white;
  padding: 0 0.5rem; }
  .RadiusPage_authAttemptsHeaderRow__VP8uB span,
  .RadiusPage_sessionHeaderRow__3pRy_ span {
    margin: 0;
    font-weight: 500;
    word-break: break-word; }

.RadiusPage_authAttemptsContainer__1zSr-,
.RadiusPage_sessionsContainer__2o9WU {
  width: 100%;
  height: calc(100% - 7rem);
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }

.RadiusPage_authAttemptsRow__2E0QM,
.RadiusPage_sessionRow__2uRIU {
  width: 100%;
  height: auto;
  min-height: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  border-bottom: 1px solid lightgray;
  padding: 0 0.5rem;
  overflow: hidden; }
  .RadiusPage_authAttemptsRow__2E0QM span,
  .RadiusPage_sessionRow__2uRIU span {
    width: 100%;
    padding: 0 0.25rem;
    height: auto; }

.RadiusPage_authAttemptedUsername__3VQyt,
.RadiusPage_authDeviceUsername__2tkgk,
.RadiusPage_authAttemptedPassword__323lp,
.RadiusPage_authDevicePassword__3P2hW,
.RadiusPage_authAttemptDate__3yjhx,
.RadiusPage_authAttemptStatus__EoDsi,
.RadiusPage_authAttemptedUsername__3VQyt,
.RadiusPage_authDeviceUsername__2tkgk {
  width: 100%;
  word-wrap: break-word; }

.RadiusPage_authAttemptStatus__EoDsi {
  max-width: 5rem; }

.RadiusPage_authAttemptDate__3yjhx {
  word-wrap: break-word; }

.RadiusPage_sessionStart__2c31I,
.RadiusPage_sessionDuration__1HpN1,
.RadiusPage_sessionTransferredData__2KGWA,
.RadiusPage_sessionTermination__1oJLe {
  width: 100%;
  word-wrap: break-word; }

.RadiusPage_sessionTransferredData__2KGWA {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .RadiusPage_sessionTransferredData__2KGWA span,
  .RadiusPage_sessionTransferredData__2KGWA i {
    margin: 0 0.25rem; }
  .RadiusPage_sessionTransferredData__2KGWA i {
    font-size: 0.9rem;
    color: white; }

.reports-page {
  height: calc(100vh - 3em);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap; }
  .reports-page h3 {
    margin: 1rem; }
  .reports-page .reports-page-left-menu {
    width: 10rem;
    height: 100%;
    padding: 0.5rem 0.75rem;
    background-color: #545b61;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    .reports-page .reports-page-left-menu strong {
      font-size: 1.1rem; }
    .reports-page .reports-page-left-menu .report-selection-container {
      width: 100%;
      height: auto;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start; }
      .reports-page .reports-page-left-menu .report-selection-container p {
        margin: 0; }
      .reports-page .reports-page-left-menu .report-selection-container .report-category-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .reports-page .reports-page-left-menu .report-selection-container .report-category-container .report-category-title {
          margin-top: 0.5rem; }
        .reports-page .reports-page-left-menu .report-selection-container .report-category-container .report-title {
          margin-left: 1rem;
          font-size: 0.9rem; }
          .reports-page .reports-page-left-menu .report-selection-container .report-category-container .report-title:hover {
            color: #f8990b;
            text-decoration: underline;
            cursor: pointer; }
        .reports-page .reports-page-left-menu .report-selection-container .report-category-container .selected-report {
          color: #f8990b;
          text-decoration: underline;
          cursor: default; }
          .reports-page .reports-page-left-menu .report-selection-container .report-category-container .selected-report:hover {
            cursor: default; }
  .reports-page .report-container {
    height: 100%;
    width: calc(100% - 10rem); }
    .reports-page .report-container rect {
      word-wrap: break-word; }
    .reports-page .report-container .report-header {
      width: 100%;
      height: 3rem;
      padding: 1rem 0.5rem;
      background-color: #6c757d;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .reports-page .report-container .report-header .report-header-left-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center; }
        .reports-page .report-container .report-header .report-header-left-container p {
          color: white;
          margin: 0 0.5rem; }
        .reports-page .report-container .report-header .report-header-left-container .report-title {
          font-size: 1.25rem; }
        .reports-page .report-container .report-header .report-header-left-container .timeframe-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center; }
          .reports-page .report-container .report-header .report-header-left-container .timeframe-container p {
            color: rgba(248, 153, 11, 0.65); }
            .reports-page .report-container .report-header .report-header-left-container .timeframe-container p:hover {
              color: #f8990b;
              cursor: pointer;
              text-decoration: underline; }
          .reports-page .report-container .report-header .report-header-left-container .timeframe-container .selected-timeframe {
            color: #f8990b;
            text-decoration: underline; }
            .reports-page .report-container .report-header .report-header-left-container .timeframe-container .selected-timeframe:hover {
              cursor: default; }
      .reports-page .report-container .report-header .report-header-right-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center; }
        .reports-page .report-container .report-header .report-header-right-container select {
          margin-right: 0.5rem; }
    .reports-page .report-container .report-content {
      width: 100%;
      height: calc(100% - 3rem);
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative; }
      .reports-page .report-container .report-content .bar-loader-container {
        width: 100%;
        position: absolute;
        top: 0; }
      .reports-page .report-container .report-content .report-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .reports-page .report-container .report-content .report-container .chart-header {
          height: 10%;
          width: 100%; }
        .reports-page .report-container .report-content .report-container .chart-container {
          height: 90%;
          width: 100%; }

.GeneralMessageModal_generalMessageModal__G1V3n {
  width: 400px;
  height: 300px;
  background-color: white;
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem; }

.GeneralMessageModal_errorList__30oR8 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; }

