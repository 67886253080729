@import '../styles/variables.scss';

// Page Styles
.page {
  height: calc(100vh - #{$navbar-height});
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.header {
  height: 10%;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
}

.buttonInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;

  a {
    text-decoration: none;
    color: inherit;
  }

  i {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }
}

.body {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.searchBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  input {
    outline: none;
    border: 2px solid $med-gray;
    &:focus {
      border-color: $accent-blue;
    }
  }

  p {
    display: none;
  }

  &:hover {
    p {
      display: block;
      margin: 0;
      position: absolute;
      right: 0.5rem;
      z-index: 2;
      color: gray;
      transition: 0.1s;

      &:hover {
        cursor: pointer;
        color: black;
        transition: 0.1s;
      }
    }
  }
}
