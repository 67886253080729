@import './variables.scss';

// Table styles
.table {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 0 10px 1px rgba(176, 176, 176, 1);
}

.header {
  height: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.columnTitleRow {
  background-color: $dk-gray;
  color: white;
  border: none;
  font-weight: bold;
}

.filterRow {
  border-bottom: 1px solid gray;
  background-color: $med-gray;
  border: none;
  font-weight: bold;

  .clearFiltersButton {
    color: white;
    margin: 0;
    font-size: 0.8rem;

    &:hover {
      color: lightgray;
    }
  }
}

.sortArrow {
  position: absolute;
  margin-left: 2px;
}

.columnTitleRow,
.filterRow,
.row {
  height: auto;
  min-height: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;

  div,
  p {
    margin: 0;
  }

  input,
  select {
    width: 90%;
  }
}

.columnTitleRow > div,
.filterRow > div,
.row > div {
  flex: 1;
}

.body {
  height: calc(100% - 4rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-y: overlay;
}

.row {
  height: auto;
  min-height: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  word-break: break-all;
  border-bottom: 1px solid lightgray;
  transition: 0.15s;
  font-size: 0.9rem;

  &:hover {
    transition: 0.15s;
    background-color: #657da1;
    color: white;
  }
}

.editRow {
  height: 4rem;
  min-height: 4rem;
  background-color: #657da1;
  color: white;
}

.rowButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;

  p {
    &:hover {
      cursor: pointer;
    }
  }
}

.saveButton {
  color: #45d200;
}

.cancelEditButton {
  color: white;
}
