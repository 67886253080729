@import '../../styles/variables.scss';

.container {
  height: 80vh;
  width: 90vw;
  max-width: 1800px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px 1px rgba(176, 176, 176, 1);
  margin-top: 1rem;
  padding: 0.5rem;

  span {
    height: 80%;
    border-right: 1px solid lightgray;
  }
}

.categorizationPanel {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
}

.panelContentContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  border: 1px solid lightgray;

  p {
    margin: 0;
    text-align: left;
  }
}

.panelContentHeader {
  width: 100%;
  height: 1.5rem;
  background-color: $dk-gray;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}

.categorizationSearchBox {
  height: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  input {
    height: 1.5rem;
    width: 100%;
    margin: 0;
    padding-left: 0.5rem;
    outline: none;
    border: 2px solid $med-gray;

    &:focus {
      border-color: $accent-blue;
    }
  }

  p {
    display: none;
  }

  &:hover {
    p {
      display: block;
      margin: 0;
      position: absolute;
      right: 0.5rem;
      z-index: 2;
      color: gray;
      transition: 0.1s;

      &:hover {
        cursor: pointer;
        color: black;
        transition: 0.1s;
      }
    }
  }
}

.panelContentList {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.categorizationListItem {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0.5rem;
  margin-bottom: 0.25rem;

  p:nth-child(1) {
    word-break: break-all;
  }

  &:hover {
    cursor: pointer;
    p {
      transform: scale(1.02);
      text-decoration: underline;
    }
  }
}

.selectedCategorizationItem {
  background-color: rgba(224, 224, 224, 0.432);
  color: $accent-blue;
  font-weight: 500;

  &:hover {
    cursor: default;

    p {
      text-decoration: none;
      transform: none;
    }
  }
}

.usageContainer {
  display: flex;
  flex-direction: row;

  p {
    margin: 0 3px;
  }
}

.customerPanel,
.devicePanel,
.uncategorizedDomainPanel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 3;
}

.categorizeDomainModal {
  height: 600px;
  width: 600px;
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.domainModalInputContainer {
  height: auto;
  width: 100%;
  p {
    margin-bottom: 0.25rem;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0.5rem;
  }
}

.createNewService {
  font-size: 0.8rem;
  color: $accent-blue;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.newServiceContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  input {
    margin: 0.5rem 0;
  }

  p {
    font-size: 0.8rem;
    margin: 0 0.5rem;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.similarDomainsContainer {
  height: 45%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  p {
    margin: 0;
  }
}

.selectAllDomains {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0.5rem;
}

.similarDomainsList {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  border: 0.5px solid lightgray;
  padding: 0.5rem;
}

.similarDomainListItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  p {
    margin: 0;
  }
}

.categorizeDomainModalButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
